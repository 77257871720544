<template>
  <div>
    <Header />
    <v-app class="patent-wrapped">
      <ScrollToTop />
      <div class="patent-header">
        <small>{{ $t("expertise_uppercase") }} / {{ $t("services") }}</small>
        <h1>{{ $t("patent") }}</h1>
      </div>
      <v-container class="patent-body">
        <p>
          {{ $t("patent_1") }}
        </p>
        <div class="d-flex align-center mb-1">
          <!-- <i class="fa-solid fa-angle-right mr-3"></i> -->
          <p class="ma-0">
            <strong>{{ $t("patent_2_bold") }}</strong><br />
            {{ $t("patent_2") }}
          </p>
        </div>
        <br />
        <div class="d-flex align-center mb-1">
          <!-- <i class="fa-solid fa-angle-right mr-3"></i> -->
          <p class="ma-0">
            <strong>{{ $t("patent_3_bold") }}</strong><br />
            {{ $t("patent_3") }}
          </p>
        </div>
        <br />
        <div class="d-flex align-center mb-1">
          <p class="ma-0">
            <strong>{{ $t("patent_4_bold") }}</strong><br />
            {{ $t("patent_4_line_1") }} <br />
            {{ $t("patent_4_line_2") }}
          </p>
        </div>
        <br />
        <div class="d-flex align-center justify-content-center mt-5 mb-1">
          <div class="ma-0 text-center card pa-2">
            <strong>{{ $t("patent_4_bold_2") }}</strong>
            <img src="~@/assets/alur-pendaftaran-paten.png" />
          </div>
        </div>
        <div class="d-flex align-center justify-content-center mt-5 mb-1">
          <div class="ma-0 text-center card pa-2">
            <strong>{{ $t("patent_4_bold_3") }}</strong>
            <img src="~@/assets/alur-pendaftaran-paten-sederhana.png" />
          </div>
        </div>
      </v-container>
    </v-app>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header-2";
import Footer from "@/components/Footer";
import ScrollToTop from "../../components/ScrollToTop.vue";

export default {
  name: "Patent",
  components: {
    Header,
    Footer,
    ScrollToTop
  },
};
</script>

<style scoped>
@font-face {
  font-family: Steelfish;
  src: url("~@/assets/fonts/steelfish-rg.otf");
}

.patent-wrapped {
  margin-top: 6.5em;
}

.patent-header {
  background: url("~@/assets/patent-bg.jpg") no-repeat;
  height: 15em;
  padding: 3em;
  background-color: #212121b3;
  color: #ffffff;
  background-blend-mode: soft-light;
  text-align: center;
  background-position-y: 55%;
}

h1 {
  font-family: "Steelfish", sans-serif;
  font-size: 7em;
}

.fa-angle-right {
  color: #8c3d40;
}

.patent-body {
  padding: 3em;
  max-width: 75%;
  line-height: 2em;
}

@media screen and (max-width: 480px) {
  .patent-body {
    max-width: 100%;
  }
}
</style>
